import styles from "../styles/codeBlock.module.scss";

import {FC, ReactNode} from "react";

type CodeKind = "good" | "bad" | "avoid";

interface IProps {
    kind?: CodeKind;  
    lang?: string;
    children?: ReactNode;
}

export const CodeBlock: FC<IProps> = ({children, kind, lang}): JSX.Element => {
    const getHeaderText = () => {
        switch (kind) {
            case "good": return "DO:";
            case "bad": return "DON'T:";
            case "avoid": return "AVOID:";
        }
     
        return "";
    };
    
    const getClass = () => {
        switch (kind) {
            case "good":
                return styles.goodExample;
            case "bad":
                return styles.badExample;
            case "avoid":
                return styles.avoidExample;
            default:
                return "";
        }    
    };
    
    return (
        <div className={`${styles.code} ${getClass()}`}>
            <div className={styles.codeHeader}>
                <span className={styles.language}>{lang ?? "C#"}</span>
            </div>

            <h3 className={getClass()}>{getHeaderText()}</h3>

            {children}
        </div>
    ); 
};