import styles from "../styles/common.module.scss";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./Home";
import {About} from "./About";
import {Kirkle} from "./Kirkle";
import {NavBar} from "./NavBar";
import {Footer} from "./Footer";
import {Standards} from "./codeStandards";
import {ProtectedRoute} from "../components/ProtectedRoute";

export const Application = () => {
    return (<BrowserRouter>
            <div className={styles.app}>
                <NavBar />            
                <main className={styles.app}>
                    <Routes>
                        <Route path={"/"}>
                            <Route path={""} element={<Home />} />
                            <Route path={"standards/*"} element={<Standards />} />
                            <Route path={"kirkle"} element={<ProtectedRoute><Kirkle /></ProtectedRoute>} />
                            <Route path={"about"} element={<About />} />
                        </Route>
                    </Routes>  
                </main>
                <Footer />
            </div>
        </BrowserRouter>
    );
};