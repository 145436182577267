import styles from "../../styles/standards.module.scss";

import {FC} from "react";
import {CodeBlock, Comment, Keyword} from "../../components";

export const CodeMaintainable: FC = () => {
    return (
        <div className="consistent-code">
            <div>
                Maintainable code is easy to read and understand which can be accomplished through code conventions. Autio Software uses the following conventions:

                Have code read like a sentence by using variables fully qualified and descriptive of what they represent:
            </div>
            <CodeBlock>
                <Keyword value="public" /> <Keyword type="property" value="Response" /> <Keyword type="method" value="GetAddressResponse" />()<br />
                {"{"} <br />
                <div className={styles.indent}>
                    <Keyword value="var" /> address = <Keyword type="property" value="LocationApi" />.<Keyword type="method" value="GetAddress" />();<br />
                    <Keyword value="var" /> response = <Keyword value="new" /> <Keyword type="method" value="AddressResponse" />(address);<br />
                    <br />
                    <Comment>code running against response/address</Comment>
                    <br />
                    <Keyword value="return" /> response;
                </div>
                {"}"} <br />
            </CodeBlock>
            <div>
                Keep consistent file layouts along with consistent spacing and naming conventions:
            </div>
            <CodeBlock>
                <Comment>variable names should be in camelCase</Comment>
                <Keyword value="var" /> camelCase = ...{";"}
                <br /><br />
                <Comment>method names should be in PascalCase, but parameters should be camelCase</Comment>
                <Keyword value="public" /> <Keyword type="method" value="PascalCase" />(<Keyword value="string" /> camelCase)
                <br /><br />
                <Comment>class, struct, record names should be PascalCase</Comment>
                <Keyword value="public class" /> <Keyword type="class" value="PascalCase" />
                <br /><br />
                <Comment>exposed properties should be PascalCase</Comment>
                <Keyword value="public property" /> <Keyword type="property" value="PascalCase" /> {"{"} <Keyword value="get"/> {"}"}
                <br /><br />
                <Comment>constants should be UPPER_SNAKE_CASE</Comment>
                <Keyword value="const int" /> UPPER_SNAKE_CASE = <Keyword type="constant" value="1" />{";"}
                <br /><br />
                <Comment>Class Layout</Comment>
                <Keyword value="public class" /> <Keyword type="class" value="MyClass"/>
                <br />{"{"}
                <div className={styles.indent}>
                    <Comment>constants and static variables/methods</Comment>
                    <br />
                    <Comment>private member variables</Comment>
                    <br />
                    <Comment>constructors</Comment>
                    <Comment>static constructors</Comment>
                    <br />
                    <Comment>protected properties</Comment>
                    <Comment>public properties</Comment>
                    <br />
                    <Comment>private methods</Comment>
                    <Comment>protected methods</Comment>
                    <Comment>public methods</Comment>
                </div>
                {"}"} <br />
            </CodeBlock>
            <div>
                Keep nesting to a minimum. Deeply nested code is hard to read.  Nesting can be reduced by exiting methods early.  It is also good practise to assert parameter values at the top of methods and exit/throw exceptions early.
                <CodeBlock kind="bad">
                    <Keyword value="public void" /> <Keyword type="method" value="Method"/>(<Keyword type="class" value="Response" /> response)<br />
                    {"{"}
                        <div className={styles.indent}>
                            <Keyword value="if" /> (response.Success)<br />
                            {"{"}
                                <div className={"indent"}>
                                    <Comment>...</Comment>
                                </div>
                            {"}"}
                        </div>
                    {"}"}
                </CodeBlock>
                <CodeBlock kind="good">
                    <Keyword value="public void" /> <Keyword type="method" value="Method"/>(<Keyword type="class" value="Response" /> response)<br />
                    {"{"}
                        <div className={styles.indent}>
                            <Keyword value="if" /> (!response.Success) <Keyword value="return" />;<br />
                            <Comment>...</Comment>
                        </div>
                    {"}"}
                </CodeBlock>
            </div>
        </div>  
    );
};