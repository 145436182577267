import styles from "../styles/kirkle.module.scss";

import {FC, useState} from "react";
import {IGuesses} from "../library";
import {KirkleState} from "../library/Kirkle";
import {isMobile} from "react-device-detect";

interface IProps {
    guesses: IGuesses;
    onClose: () => void;
}

export const ShareKirkle: FC<IProps> = ({guesses, onClose}) => {
    const [showCopyText, setTextCopied] = useState(false);
    
    const getTile = (value: boolean | undefined) => {
        switch (value) {
            case true: return "🟧";
            case false: return "⬛";
            default: return "🟦";
        }
    };
    const shareGrid = guesses.guesses.map(g => `${g.letterCorrectness.map(getTile).join("")}`).join("\n");
    const shareData = `KIRKLE (${new Date().toLocaleDateString("default", {year: "2-digit", month: "short", day: "numeric"})}) - ${guesses.state === KirkleState.Won ? guesses.guesses.length : "X"}/6\n\n${shareGrid}`;
    
    const share = async () => {
        if (isMobile && navigator.canShare({text: shareData}))
            await navigator.share({text: shareData});
        else {
            await navigator.clipboard.writeText(shareData);
            setTextCopied(true);
            setTimeout(() => setTextCopied(false), 2000);
        }
    };
    
    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                <div className={styles.heading}>
                    {guesses.state === KirkleState.Won
                        ? `Congrats!  You guessed the word correctly in ${guesses.guesses.length} attempts`
                        : <>Better luck next time!  The correct word was: <span className={styles.correctWord}>{guesses.correctWord.toUpperCase()}</span></>
                    }
                    <div className={styles.close}>
                        <a href={"#"} onClick={onClose}>X</a>
                    </div>
                </div>
                <hr />
                
                <div className={styles.actions}>
                    <button className={styles.share} onClick={share}>Share</button>
                </div>
                <div className={styles.textCopied}>{showCopyText && "Results copied to clipboard"}</div>
            </div>
        </div>
    );
};