import commonStyles from "../../styles/common.module.scss";
import styles from "../../styles/standards.module.scss";

import {FC} from "react";
import {NavLink, Route, Routes} from "react-router-dom";
import {CodeMaintainable} from "./CodeMaintainable";
import {CodeExtensible} from "./CodeExtensible";
import {SourceControl} from "./SourceControl";

export const Standards: FC = () => {
    const getClass = ({isActive}: {isActive: boolean}) => `${styles.link} ${isActive ? styles.active : ""}`;

    return (
        <div className={`${commonStyles.content}`}>
            <div className={styles.standardLinks}>
                <NavLink end className={getClass} to={""}>Write maintainable code</NavLink><wbr />
                <NavLink className={getClass} to={"codeExtensible"}>Write extensible code</NavLink><wbr />
                <NavLink className={getClass} to={"sourceControl"}>Continuous Integration/Delivery</NavLink><wbr />
            </div>

            <div className={styles.standardContent}>
                <Routes>
                    <Route path={""}>
                        <Route index element={<CodeMaintainable />} />
                        <Route path={"codeExtensible"} element={<CodeExtensible />} />
                        <Route path={"sourceControl"} element={<SourceControl />} />
                    </Route>
                </Routes>
            </div>
        </div>
    );
};