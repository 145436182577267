import commonStyles from "../styles/common.module.scss";
import styles from "../styles/home.module.scss";

import {FC} from "react";

export const Home: FC = () => {
    return (
        <div className={styles.home}>
            <div className={styles.banner}>
                <img src="/assets/profile.png" alt="profile"/>
                <div>
                    <h1>Expert in <span className={commonStyles.underline}>Web Applications</span></h1>
                    <i>Save time and money hiring developers with experience.</i>
                    <h1><span className={commonStyles.underline}>20 years</span> of development experience</h1>
                    <i>Make your development team better by sharing knowledge.  Mentorship and best practises will reduce mistakes and ensure progress.</i>
                </div>
            </div>
            <div className={commonStyles.content}>
                <h1 className={commonStyles.centerText}>Helping businesses succeed since 2020</h1>
                <div className={styles.partners}>
                    <img src="/assets/aps_logo2000.png" alt="Alberta Pensions Services" />
                    <img src="/assets/Alberta-government-logo2.png" alt="Government of Alberta" />
                </div>
            </div>
        </div>
    );
};