import {IGuess, IGuesses} from "./";
import axios from "axios";

interface IApiCalls {
    getGuesses?: () => Promise<IGuesses>;
    submitGuess?: (guess: IGuess) => Promise<IGuesses>;
}

class ApiCalls implements IApiCalls {
    readonly getGuesses?: () => Promise<IGuesses>;
    readonly submitGuess?: (guess: IGuess) => Promise<IGuesses>;
    
    constructor(apiConfig: any) {
        if (!apiConfig.headers.auth0) return;
        
        this.getGuesses = async () => (await axios.get<IGuesses>(`${process.env.REACT_APP_API_ENDPOINT}group/6fcd86d7-a4f9-4f8d-a54d-be5f7d1f1e03/guess/`, apiConfig)).data;
        this.submitGuess = async (guess: IGuess) => (await axios.post<IGuesses>(`${process.env.REACT_APP_API_ENDPOINT}group/6fcd86d7-a4f9-4f8d-a54d-be5f7d1f1e03/guess/${guess.word}`, {}, apiConfig)).data;
    }
}

export {
    IApiCalls,
    ApiCalls
};