import {FC, ReactNode, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

interface IProps {
    children?: ReactNode;
}

export const ProtectedRoute: FC<IProps> = ({children}) => {
    const auth = useAuth0();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    
    useEffect(() => {
        if (auth.isLoading) return;

        setIsLoggedIn(auth.isAuthenticated);
        if (!auth.isAuthenticated) auth.loginWithRedirect({redirectUri: document.location.href}).then();
    }, [auth]);
    
    return isLoggedIn ? <>{children} <Outlet /></> : <></>;
};