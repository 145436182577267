import styles from "../styles/comments.module.scss";

import {FC, ReactNode} from "react";

interface IProps {
    children?: ReactNode;
}

export const Comment: FC<IProps> = ({children}) => {
    return <div className={styles.comments}>{"//"}{children}</div>;
};