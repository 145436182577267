import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {apiCallsState, authHeaderState} from "../state/AuthState";

export const useApi = () => {
    const auth = useAuth0();
    const setApiConfig = useSetRecoilState(authHeaderState);
    const apiCalls = useRecoilValue(apiCallsState);
    
    useEffect(() => {
        if (!auth.isAuthenticated) return;
        
        const loadToken = async () => setApiConfig({headers: {auth0: await auth.getAccessTokenSilently()}});
        loadToken().then();
    }, [auth, setApiConfig]);
        
    return apiCalls;
};