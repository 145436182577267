import styles from "../../styles/standards.module.scss";

import {CodeBlock, Keyword} from "../../components";

/*eslint no-template-curly-in-string: "off"*/
export const SourceControl = () => {
    return (
        <div>
            <div>
                <h4>Quality Assurance should be automated as much as possible.</h4>  With a proper CI/CD solution code errors can be caught before they are deployed.  Autio Software stores its source in
                {" "}<a href="https://github.com/kirk-autio-sdc/asdc-web" target="_blank" rel="noreferrer">GitHub</a> with triggered Actions.  All work must be done in a separate branch and then merged back into the main branches through a Pull Request.
                This allows code to be reviewed prior to check-in.  When code is checked into a branch an action is triggered to ensure that the project will build and that all tests pass.
            </div>
            <div>
                <br/>
                Deployment is done through build actions to avoid mistakes and enforce consistency across deployments.  Below is a standard build file that Autio Software uses:
            </div>
            <CodeBlock lang="yml">
                <Keyword value="name" />: Build and Deploy <br />
                <br />
                <Keyword value="on" />:<br />
                <div className={styles.indent}>
                    <Keyword value="push" />
                    <div className={styles.indent}><Keyword value="branches" />: [ master ]</div>
                </div>
                <br />
                <Keyword value="env" />:<br />
                <div className={styles.indent}><Keyword value="app" />: publish-app</div>
                <br />
                <Keyword value="jobs" />:<br />
                <div className={styles.indent}>
                    <Keyword value="build" />:<br />
                    <div className={styles.indent}>
                        <Keyword value="runs-on" />: ubuntu-latest
                        <br /><br />
                        <Keyword value="steps" />:<br />
                        <div className={styles.indent}>
                            - <Keyword value="uses" />: actions/checkout@v2<br />
                            <br />
                            - <Keyword value="name" />: NPM Install<br />
                            <div className={styles.indent}>
                                <Keyword value="run" />: npm install
                            </div>
                            <br />
                            - <Keyword value="name" />: Build files
                            <div className={styles.indent}>
                                <Keyword value="run" />: npm run build -- --dest <span>{"${{ env.app }}"}</span>
                            </div> 
                            <br />
                            - <Keyword value="name" />: Upload artifacts
                            <div className={styles.indent}>
                                <Keyword value="uses" />: actions/upload-artifacts@v2<br />
                                <Keyword value="with" />:
                                <div className={styles.indent}>
                                    <Keyword value="name" />: app<br />
                                    <Keyword value="path" />: <span>{"${{ env.app }}"}</span>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <br />
                    <Keyword value="deploy" />:<br />
                    <div className={styles.indent}>
                        <Keyword value="needs" />: [ build ]<br />
                        <Keyword value="runs-on" />: ubuntu-latest<br />
                        <br />
                        <Keyword value="steps" />:<br />
                        <div className={styles.indent}>
                            - <Keyword value="name" />: Get artifacts
                            <div className={styles.indent}>
                                <Keyword value="uses" />: actions/download-artifacts@v2<br/>
                                <Keyword value="with" />:
                                <div className={styles.indent}>
                                    <Keyword value="name" />: app<br />
                                    <Keyword value="path" />: <span>{"${{ env.app }}"}</span>
                                </div>
                            </div> 
                            <br />
                            - <Keyword value="name" />: Deploy
                            <div className={styles.indent}>
                                <Keyword value="uses" />: azure/webapps-deploy@v2
                                <Keyword value="with" />:
                                <div className={styles.indent}>
                                    <Keyword value="app-name" />: '<span>{"${{ secrets.AZURE_APP }}"}</span>'<br />
                                    <Keyword value="slot-name" />: 'production'<br />
                                    <Keyword value="publish-profile" />: <span>{"${{ secrets.PUBLISH_PROFILE }}"}</span><br />
                                    <Keyword value="package" />: '<span>{"${{ env.app }}"}</span>'
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CodeBlock>
        </div>
    );
};