import {atom, selector} from "recoil";
import {ApiCalls, IApiCalls} from "../library/ApiCalls";

export const authHeaderState = atom<{headers: any}>({
    key: "authHeader",
    default: {headers: {auth0: ""}}
});

export const apiCallsState = selector<IApiCalls>({
    key: "apiCalls",
    get: ({ get }) => new ApiCalls(get(authHeaderState)),
});