import styles from "../styles/navBar.module.scss";

import {FC} from "react";
import {Link, NavLink} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

export const NavBar: FC = () => {
    const getClass = ({isActive}: {isActive: boolean}): string => `${styles.link} ${isActive ? styles.active : ""}`;
    
    return (
        <header className={styles.nav}>
            <img alt={"Autio Software Development"} src={"/assets/logo-txt.png"} />
            <div className={styles.routes}>
                <NavLink className={getClass} to={"/"}>Home</NavLink>
                <NavLink className={getClass} to={"/standards"}>Standards</NavLink>
                <NavLink className={getClass} to={"/kirkle"}>Kirkle</NavLink>
                <NavLink className={getClass} to={"/about"}>About</NavLink>
                <LogInOut />
            </div>
        </header>
    );
};

const LogInOut: FC = () => {
    const auth = useAuth0();
    
    if (auth.isAuthenticated)
        return (<span className={styles.username}>{auth.user?.email} ( <Link className={`${styles.logout} ${styles.link}`} to={document.location.origin} onClick={() => auth.logout({returnTo: `${document.location.origin}/`})}>Log out</Link> )</span>);
        
    return <Link className={styles.link} to={document.location.href} onClick={() => auth.loginWithRedirect({redirectUri: document.location.href})}>Login</Link>;
}; 