import commonStyles from "../styles/common.module.scss";
import styles from "../styles/kirkle.module.scss";

import {FC, useEffect, useState} from "react";
import {Keyboard, Spinner} from "../components";
import {IGuess, IGuesses} from "../library";
import {useApi} from "../hooks";
import {ShareKirkle} from "../components/ShareKirkle";
import {KirkleState} from "../library/Kirkle";

export const Kirkle: FC = () => {
    const {getGuesses, submitGuess} = useApi();
    
    const [loading, setLoading] = useState(true);
    const [revealing, setRevealing] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [invalidWord, setInvalidWord] = useState(false);
    const [currentGuess, setCurrentGuess] = useState<IGuess>({word: "", letterCorrectness: []});
    const [guesses, setGuesses] = useState<IGuesses>({guesses: [], incorrectLetters: "", correctLetters: "", missedLetters: "", correctWord: "", state: KirkleState.Playing});
    
    useEffect(() => {
        if (!getGuesses) return;
        
        const loadGuesses = async () => {
            const guesses = await getGuesses();
            setGuesses(guesses);
            if (guesses.state !== KirkleState.Playing) setShowModal(true);
        };
        
        loadGuesses().then(() => setLoading(() => false));
    }, [getGuesses]);
    
    const getLetterStyle = (wordIndex: number, letterIndex: number): string[] => {
        const result = letterIndex <= revealing && wordIndex === guesses.guesses.length - 1
            ? [styles.flip]
            : [];
        
        if (letterIndex > revealing && revealing >= 0)
            return result;
        
        const correctness = guesses.guesses[wordIndex].letterCorrectness[letterIndex];
        
        return [...result, correctness === null
            ? styles.miss
            : correctness ? styles.correct : styles.wrong];
    };
    
    const getKeyStyle = (key: string) => {
        if (guesses.correctLetters.includes(key))
            return [styles.correct];
        
        if (guesses.missedLetters.includes(key))
            return [styles.miss];

        if (guesses.incorrectLetters.includes(key))
            return [styles.wrong];

        return [styles.unused];
    };
    
    const handleKeyPress = (key: string) => {
        if (guesses.state !== KirkleState.Playing || loading || currentGuess.word.length === 5) return;

        setInvalidWord(false);
        setCurrentGuess(guess => ({...guess, word: guess.word + key}));
    };
    
    const deleteLast = () => {
        if (!currentGuess || guesses.state !== KirkleState.Playing) return;
        setCurrentGuess({...currentGuess, word: currentGuess.word.slice(0, -1)});
    };
    
    const startRevealing = (index: number) => {
        if (index > 5) {
            setRevealing(-1);
            return;
        }
        
        setRevealing(index);
        if (index < 6)
            setTimeout(() => startRevealing(index + 1), 100);
    };
    
    const onSubmitGuess = async () => {
        if (currentGuess.word.length !== 5 || !submitGuess) return;

        setLoading(true);
        try {
            const guesses = await submitGuess(currentGuess);
            if (guesses.invalid) {
                setInvalidWord(true);
                return;
            }
            
            startRevealing(0);
            setGuesses(guesses);
            if (guesses.state !== KirkleState.Playing) setShowModal(true);
        } finally {
            setCurrentGuess({word: "", letterCorrectness: []});
            setLoading(false);
        }
    };
    
    return (
        <div className={commonStyles.content}>
            <div className={styles.guesses}>
                {[...guesses.guesses, ...(guesses.state === KirkleState.Playing ? [currentGuess] : [])].slice(0, 6).map((guess, index) =>
                    <div className={styles.guess} key={index}>
                        {[...Array(5).keys()].map(letterIndex => {
                            return <div className={`${index < guesses.guesses.length || guesses.state !== KirkleState.Playing ? getLetterStyle(index, letterIndex).join(" ") : ""} ${styles.kirkleInput}`} key={letterIndex}>{guess.word?.[letterIndex]}</div>;
                        })}
                        <div className={styles.spinner}>
                           {loading && index === guesses.guesses.length && <Spinner v-if="loading && index === this.guesses.guesses.length" />}
                           {invalidWord && index === guesses.guesses.length && <span>X</span>}
                        </div>
                    </div>
                )}
            </div>

            <Keyboard keyStyle={getKeyStyle} onEnter={onSubmitGuess} onDelete={deleteLast} onKeyPress={handleKeyPress} />
            {showModal && <ShareKirkle guesses={guesses} onClose={() => setShowModal(false)} />}
        </div>
    ); 
};