import styles from "../../styles/standards.module.scss";

import {CodeBlock, Comment, Keyword} from "../../components";

export const CodeExtensible = () => {
    return (
        <div>
            <h3>Default to immutable</h3>
            <div>
                Immutable objects help to enforce code centralization, reduce problems with threading, and create cleaner code.  Properties should only be made mutable if absolutely necessary and should actually be changing.
                For example, a house's address should likely be immutable.  In the real world it would be very rare for an address to change.  It could really only change if street names were updated.  And even then instead of mutating the address,
                you could just create a new address object with the change.
                <CodeBlock kind="bad">
                    <Comment>Logic is scattered through the system and may be replicated.  It's not obvious that there may be constraints</Comment>
                    <Keyword value="public class" /> <Keyword type="class" value="Address" /><br />
                    {"{"} <br />
                        <div className={styles.indent}>
                        <Keyword value="public" /> <Keyword type="class" value="string" /> <Keyword type="property" value="PostalCode"/> {"{"} <Keyword value="get" />{";"} <Keyword value="set" /> {"}"}<br />
                        <Keyword value="public" /> <Keyword type="class" value="string" /> <Keyword type="property" value="Street"/> {"{"} <Keyword value="get" />{";"} <Keyword value="set" /> {"}"}<br />
                        <Keyword value="public" /> <Keyword type="class" value="string" /> <Keyword type="property" value="City"/> {"{"} <Keyword value="get" />{";"} <Keyword value="set" /> {"}"}
                        </div>
                    {"}"}
                </CodeBlock>
                <CodeBlock kind="good">
                    <Comment>Any logic pertaining to Address should be contained, ensure centralized logic and no duplication</Comment>
                    <Keyword value="public class" /> <Keyword type="class" value="Address" /><br />
                    {"{"} <br />
                        <div className={styles.indent}>
                        <Keyword value="public" /> Address(<Keyword type="class" value="string" /> street, <Keyword type="class" value="string" /> postalCode, <Keyword type="class" value="IAddressChecker" /> addressChecker)<br />
                        {"{"} <br />
                            <div className={styles.indent}>
                            <Keyword value="if" /> (!addressChecker.IsValid(street, postalCode, city))<br />
                            <div className={styles.indent}><Keyword value="throw new" /> <Keyword value="method" /> <Keyword type="method" value="AddressException" />(<Keyword type="constant" value="&quot;Invalid Address&quot;" />);</div>
                            <br />
                            <Keyword type="property" value="PostalCode"/> = postalCode;<br />
                            <Keyword type="property" value="Street"/> = street;<br />
                            <Keyword type="property" value="City"/> = city;
                            </div>
                        {"}"} <br />
                        <br />
                        <Keyword value="public" /> <Keyword type="class" value="string" /> <Keyword type="property" value="PostalCode"/> {"{"} <Keyword value="get" />{"; }"}<br />
                        <Keyword value="public" /> <Keyword type="class" value="string" /> <Keyword type="property" value="Street"/> {"{"} <Keyword value="get" />{"; }"}<br />
                        <Keyword value="public" /> <Keyword type="class" value="string" /> <Keyword type="property" value="City"/> {"{"} <Keyword value="get" />{"; }"}
                        </div>
                    {"}"}
                </CodeBlock>
            </div>
            <h3>Favor composition over inheritance</h3>
            <div>
                Classes that use dependency injection to specify behaviour can be changed without affecting pre-existing code.  Existing code doesn't need to be retested; only the new behaviour class requires testing.
                <CodeBlock kind="avoid">
                    <Keyword value="public abstract class" /> <Keyword type="class" value="Container" /><br />
                    {"{"} <br />
                        <div className={styles.indent}>
                        <Keyword value="public abstract" /> <Keyword type="class" value="string" /> <Keyword type="property" value="Color"/> {"{"} <Keyword value="get" />{"; }"}<br />
                        </div>
                    {"}"}<br />
                    <br />
                    <Keyword value="public class" /> <Keyword type="class" value="WarningContainer" /> : <Keyword type="class" value="Container" /><br />
                    {"{"} <br />
                        <div className={styles.indent}>
                        <Keyword value="public override" /> <Keyword type="class" value="string" /> <Keyword type="property" value="Color"/> {"=>"} <Keyword type="constant" value="&quot;Orange&quot;" /><br />
                        </div>
                    {"}"}<br />
                    <br />
                    <Keyword value="public class" /> <Keyword type="class" value="ErrorContainer" /> : <Keyword type="class" value="Container" /><br />
                    {"{"} <br />
                        <div className={styles.indent}>
                        <Keyword value="public override" /> <Keyword type="class" value="string" /> <Keyword type="property" value="Color"/> {"=>"} <Keyword type="constant" value="&quot;Red&quot;" /><br />
                        </div>
                    {"}"}
                </CodeBlock>
                <CodeBlock kind="good">
                    <Keyword value="public class" /> <Keyword type="class" value="Container" /><br />
                    {"{"} <br />
                        <div className={styles.indent}>
                        <Comment>IStatus checker allows us to reuse status color coding across more than just this class.  </Comment>
                        <Comment>Allows us to change colors rapidly by centralizing logic.</Comment>
                        <Keyword value="public" /> Container(<Keyword type="class" value="IStatusChecker" /> statusChecker, <Keyword type="class" value="Status" /> status)<br />
                        {"{"}<br />
                            <div className={styles.indent}>
                            <Keyword type="property" value="Color"/> = statusChecker.ColorFor(status);
                            </div>
                        {"}"}<br />
                        <br />
                        <Keyword value="public" /> <Keyword type="class" value="string" /> <Keyword type="property" value="Color"/> {"{"} <Keyword value="get" />{"; }"}<br />
                        </div>
                    {"}"}
                </CodeBlock>
            </div>
        </div>
    );
};