import "./styles/index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import {Application} from "./pages";
import {Auth0Provider} from "@auth0/auth0-react";
import {RecoilRoot} from "recoil";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <Auth0Provider domain={"autio-software.us.auth0.com"} clientId={"GbkJudwEQvuHaneDTVXaunclmNj7LHTg"} redirectUri={document.location.origin}>
                <Application />
            </Auth0Provider>
        </RecoilRoot>
    </React.StrictMode>
);
