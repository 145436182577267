import styles from "../styles/keyword.module.scss";

import {FC} from "react";

interface IProps {
    value: string;
    type?: "keyword" | "constant" | "method" | "class" | "property";
}

export const Keyword: FC<IProps> = ({value, type}) => {
    return <span className={styles[type ?? "keyword"]}>{value}</span>;
};