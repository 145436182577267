import styles from "../styles/keyboard.module.scss";

import {FC, useEffect} from "react";

interface IProps {
    keyStyle: (key: string) => string[];
    onEnter: () => void;
    onDelete: () => void;
    onKeyPress: (key: string) => void;
}

export const Keyboard: FC<IProps> = ({keyStyle, onEnter, onDelete, onKeyPress}) => {
    const keyRows: string[] = [
        "QWERTYUIOP",
        "ASDFGHJKL",
        "\rZXCVBNM~"   
    ];
    
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.ctrlKey || event.altKey || event.metaKey) return;

            if (/^[a-zA-Z]{1}$/.test(event.key)) {
                onKeyPress(event.key.toUpperCase());
                return;
            }

            switch (event.key) {
                case "Enter":
                    onEnter();
                    break;
                case "Backspace":
                    onDelete();
                    break;
            }

        };
        
        window.addEventListener("keydown", handleKeyPress);
        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [onKeyPress, onEnter, onDelete]);
    
    const getKeyInfo = (key: string) => {
        const style = [...keyStyle(key), styles.keyboard, styles.button];

        switch (key) {
            case "\r": return {text: "Enter", style: [...style, styles.wide], event: onEnter};
            case "~": return {text: "", style: [...style, styles.wide], event: onDelete};
            default: return {text: key.toUpperCase(), style: style, event: onKeyPress};
        }
    };
    
    const keyClassFor = (key: string) => getKeyInfo(key).style.join(" ");
    const eventFor = (key: string) => getKeyInfo(key).event(key);
    const replace = (key: string) => getKeyInfo(key).text;
    
    return (
        <>{keyRows.map(row => 
            <div className={styles.keyboard} key={row}>
                {[...row].map(key => (
                    <button className={keyClassFor(key)} key={key} onClick={() => eventFor(key)}>
                        {key === "~" &&
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className={styles.backspace}>
                                <path d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"/>
                            </svg>
                        }
                        {replace(key)}
                    </button>

                ))}
            </div>
        )}</>
    );
};