import styles from "../styles/spinner.module.scss";

import {FC} from "react";


export const Spinner: FC = () => {
    return (
        <div className={styles.orbitSpinner}>
            <div className={styles.orbit}></div>
            <div className={styles.orbit}></div>
            <div className={styles.orbit}></div>
        </div>
    );
};