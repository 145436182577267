export enum KirkleState {
    Playing,
    Won,
    Lost
}

export interface IGuesses {
    guesses: IGuess[];
    correctLetters: string;
    missedLetters: string;
    incorrectLetters: string;
    invalid?: boolean;
    state: KirkleState;
    correctWord: string;
}

export interface IGuess {
    word: string;
    letterCorrectness: Array<boolean | undefined>;
}